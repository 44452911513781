<template>
  <v-container fluid>
    <page-title title="Как работает нейрографика?" />
    <transition appear name="fadeGroup">
      <v-row justify="space-around" align="center">
        <v-col cols="12" sm="11">
          <v-card>
            <v-card-text :class="{ 'body-2': $vuetify.breakpoint.xs }" class="body-1 text-justify">
              Нейрографика – молодой инструмент. Она появилась в 2015 году, именно этот год
              указывает годом рождения нейрографики ее автор Павел Пискарев. <br />
              С тех пор нейрографика значительно распространилась по миру, особенно по
              русскоязычному пространству, обросла не только полезными фишками, но и массой мифов.
              <br />
              Именно из-за этих мифов нейрографику порой считают сектой, шарлатанством и даже черной
              магией. Но если знать, что лежит в основе этого рисования, то становится понятно, что
              никакой эзотерики в нейрографике нет. Работает психология – законы восприятия, теория
              коллективного бессознательного и арт-терапия. <br />
              Итак, изначально нейрографика была заявлена (и до сих пор это самый привлекающий в ней
              аргумент) как инструмент для решения задач, достижения целей и… исполнения желаний.
              Стремление найти волшебную палочку, которая исполняет желание, никогда не ослабеет. В
              нейрографике волшебной палочкой был объявлен маркер, и тысячи новых адептов принялись
              рисовать удивительные нейрографические линии в ожидании чуда. И чудеса не заставили
              себя ждать! По поиску в глобальной сети вы найдете тысячи отзывов и подтверждений
              того, что желания исполняются, то есть нейрографика работает! И она действительно
              работает. Но за счет чего?
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" sm="8">
          <v-card>
            <v-card-text :class="{ 'body-2': $vuetify.breakpoint.xs }" class="body-1 text-justify">
              1. Линия. <br />
              <br />
              Привет первобытным людям с их наскальными рисунками. Отражение мира на стене пещеры,
              бумаге, холсте посредством самых примитивных линий – это человеческий способ овладеть
              реальностью. Неважно, что вы будете делать – писать утренние страницы, рисовать
              нейрографику или чертить схемы завоевания мира. Работает все. Поле вашего листа
              становится миром, линии и буквы – его трансформируют. Никакой магии. Просто
              подтверждение того, что часть отражает целое и работает по его законам. А изменение в
              части объекта приводит к изменениям в объекте в целом. Меняйте доступное вам поле
              реальности - для мозга это равнозначно возможности изменить реальность целиком.
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="3">
          <v-img
            contain
            max-height="300"
            class="white--text align-end"
            src="https://firebasestorage.googleapis.com/v0/b/first-project-6daea.appspot.com/o/blog%2FNeurographics%2Fmaskalnaja_3.jpg?alt=media&token=8ecd6d2e-bf82-456c-afb8-6a249ad95d91"
          />
        </v-col>
        <v-col cols="12" sm="11">
          <v-card>
            <v-card-text :class="{ 'body-2': $vuetify.breakpoint.xs }" class="body-1 text-justify">
              2. Собственно нейрографическая линия. <br />
              <br />
              Прямые и перпендикулярные линии, линии с повторяющимися паттернами – про логику,
              доказательную часть мира, а значит – про ограничения и ту самую клетку, за которой так
              тяжело выйти. Мозг держит нас взаперти. Позволяет происходить только тому, что можно
              увидеть и логически спрогнозировать. Нейрографическая линия – природная, живая,
              обходящая ограничения, проникающая в ту реальность, которая недоступна логике. Рисуя
              нейрографические линии, мы даем себе возможность выйти за ограничения установок.
              Посмотрите, как похожа нейрографическая линия на корень растения, проникающий сквозь
              самые невероятные препятствия в поисках источника жизни. Если вы будете просто
              рисовать нейрографические линии – вы уже начнете развивать пластичность мозга.
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="8">
          <v-card>
            <v-card-text :class="{ 'body-2': $vuetify.breakpoint.xs }" class="body-1 text-justify">
              3. Скругления. <br />
              <br />
              Один из основополагающих принципов нейрографики – скругления пересечений. За счет
              скругления пересекающиеся между собой линии становятся целостной фигурой. Так устроено
              восприятие человека: мы всегда стремимся увидеть целостную фигуру, выделить фигуру из
              фона. Видимый мир по сути представляет собой набор линий и точек, но наш мозг
              складывает из них целостные объекты. Пока ситуация видится нам набором обрывочных
              задач и мыслей – она кажется нерешаемой. Превратите хаос линий в целостный гармоничный
              объект – и мозг сможет его освоить.
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="3">
          <v-img
            contain
            max-height="300"
            class="white--text align-end"
            src="https://firebasestorage.googleapis.com/v0/b/first-project-6daea.appspot.com/o/blog%2FNeurographics%2Foptic_1.jpg?alt=media&token=849be7b4-0e6d-49e5-a844-95df8c03880b"
          />
        </v-col>
        <v-col cols="12" sm="8">
          <v-card>
            <v-card-text :class="{ 'body-2': $vuetify.breakpoint.xs }" class="body-1 text-justify">
              4. Архетипические фигуры. <br />
              <br />
              Тут мы вспоминаем Карла Густава Юнга, открывшего силу символов и коллективного
              бессознательного. Используя символы бессознательного – мы прямо к бессознательному и
              обращаемся. Любое послание «в лоб» блокируется сознанием, если расценивается как
              опасное. Например, если для вашего сознания по причине каких-то установок опасно иметь
              дом (например, из поколения в поколение передается история о раскулачивании), то рисуя
              дом вы создаете опасность и мозг блокирует такую реальность. Если же вы рисуете
              квадрат, то глубинные смыслы будут считаны бессознательным, мозг же эту информацию
              спокойно пропустит. В нейрографике используются и геометрические фигуры, как наиболее
              универсальные символы бессознательного, так и более конкретные символы – дерево,
              сосуд, лотос и т.д.
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="3">
          <v-img
            contain
            max-height="300"
            class="white--text align-end"
            src="https://firebasestorage.googleapis.com/v0/b/first-project-6daea.appspot.com/o/blog%2FNeurographics%2Fderevo_4.jpg?alt=media&token=47bd5474-47be-459d-8957-dba416d27714"
          />
        </v-col>
        <v-col cols="12" sm="11">
          <v-card>
            <v-card-text :class="{ 'body-2': $vuetify.breakpoint.xs }" class="body-1 text-justify">
              5. Ритуалы. <br />
              <br />
              Ритуалы призваны «обмануть» мозг. Если нет доказательства происходящему – создайте
              ритуал, и в него поверят без каких-либо доказательств. Например, можно носить амулет и
              верить, что не заболеешь. При этом в качестве амулета могут выступать любые предметы,
              понятные в культуре конкретного человека. Кто-то носил на груди бусы из чеснока,
              кто-то бусы из зубов врага, кто-то значок «я здоров». Результат для мозга примерно
              одинаков. В нейрографике существует только один стопроцентный ритуал – это рисование
              линий поля. По сути он работает как: «А теперь все заработало!», то есть является
              графическим заклинанием. Что поделать, нам нужны заклинания для того, чтобы обозначить
              точку отсчета начала изменений в жизни. Все остальные ритуалы – рисовать только так, а
              не иначе, только определенными маркерами и т.д. – личные фантазии инструктора
              нейрографики. Никакого отношения к работе сознания и бессознательного они не имеют.
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="3">
          <v-img
            contain
            max-height="500"
            class="white--text align-end"
            src="https://firebasestorage.googleapis.com/v0/b/first-project-6daea.appspot.com/o/blog%2FNeurographics%2Fnejro_4_v.jpg?alt=media&token=a3461111-b3af-4b69-8de7-17e749e63aa0"
          />
        </v-col>
        <v-col cols="12" sm="8">
          <v-card>
            <v-card-text :class="{ 'body-2': $vuetify.breakpoint.xs }" class="body-1 text-justify">
              Где научиться нейрографике? <br />
              <br />
              В глобальной сети интернет множество платных и бесплатных уроков, курсов,
              алгоритмов.<br />
              Metamorfosi предлагают самый полный курс
              <router-link :to="pathNeirografika"> <b>«Базовая нейрографика»</b></router-link> для
              освоения навыков нейрографики: от базового алгоритма до моделирования реальности.
              <br />Курс содержит 14 алгоритмов нейрографики: базовый, а также авторские разработки
              Надежды Навроцкой<br />
              «Круги на воде» — о влиянии вашего опыта и достижений на будущее; <br />«Зарождение
              нового» — материализация идеи; <br />«Движение к цели» — энергия развития проекта;
              <br />«Фундамент» — создание фундамента для движения вперед; <br />«Звезда достижений»
              — увеличение своего влияния и значимости, повышение самооценки; <br />«Колесо
              трансформации» — про внутренние изменения; <br />«Власть и покровительство» —
              обретение покровителя или помощника; <br />«Путешествие во времени» — два алгоритма, в
              которых вы сможете вернуться в прошлое, освободиться от травм, после чего обновленным
              пойти в будущее; <br />«Очищение» — освобождение от негативных переживаний и обид;
              <br />«Ресурсы из будущего» — возможность начать пользоваться своим успешным будущим
              прямо сейчас; <br />«Сворачивание событий» — закрытие негативных сценариев, событий и
              отношений; <br />«Расширение влияния» — расширение своего влияния на мир.
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </transition>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'BlogNeurographics',
  metaInfo: {
    title: 'Как работает нейрографика',
    titleTemplate: null,
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'Как и почему работает нейрографика'
      },
      {
        property: 'og:url',
        content: 'https://metamorfosi.eu/blog/neurographics'
      },
      {
        property: 'og:title',
        content: 'Как работает нейрографика?'
      },
      {
        property: 'og:description',
        content: 'Как и почему работает нейрографика'
      },
      {
        property: 'og:image',
        content:
          'https://firebasestorage.googleapis.com/v0/b/first-project-6daea.appspot.com/o/blog%2FNeurographics%2Fnejro_4_v.jpg?alt=media&token=a3461111-b3af-4b69-8de7-17e749e63aa0'
      }
    ]
  },
  components: {
    PageTitle: () => import('@/components/shared/PageTitle')
  },
  computed: {
    ...mapState({
      loading: state => state.shared.loading,
      user: state => state.user.user
    }),
    pathNeirografika() {
      if (this.user.payments && this.user.payments.neurography) {
        return `/studios/home/neurography`
      } else {
        return `/studios/preview/neurography`
      }
    }
  }
}
</script>

<style scoped lang="stylus">
.fadeGroup-enter-active, .fadeGroup-leave-active {
  transition: opacity 2s;
}
.fadeGroup-enter, .fadeGroup-leave-to {
  opacity: 0;
}
</style>
